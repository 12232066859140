/*! This CSS file is only to be used in Login page */

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    background-color: #fcfcfc !important;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

p {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
}

input,
select {
    font-family: 'Poppins', sans-serif !important;
}

a {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    color: #966837;
}

label,
span,
li,
input {
    font-family: 'Poppins', sans-serif;
}

body .form-check-input:checked {
    background-color: #966837;
    border-color: #966837;
    box-shadow: none;
}

body .btn {
    font-family: 'Poppins', sans-serif !important;
    border-radius: 5px;
    font-size: 15px !important;
    font-weight: 400;
    padding: 10px 15px;
}

.btn.brownBtn {
    background: #966837;
    border-color: #966837;
    color: #fff;
}

.btn.brownBtn:hover,
.btn.brownBtn:focus {
    background: #835c32 !important;
    border-color: #835c32 !important;
    color: #fff;
}

.btn.whiteBtn {
    background: #fff;
}

.btn.whiteBtn:hover,
.btn.whiteBtn:focus {
    background: #fff !important;
    cursor: inherit;
}



.btn img {
    max-height: 20px;
}

/* New login page design */

.login-container2 {
    position: relative;
    background-image: url('../img/bg.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.login-container2::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
    z-index: -1;

}


.main-container {
    /* background-color: rgba(38, 99, 255, 0.8); */
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

}

.login__logo2 {
    width: 95%;
    display: flex;
    margin-top: 1rem;
    /* background-color: #0EC970; */
}

.login__logo2 img {

    left: 0;
    height: 5rem;
    align-items: center;

}

.form-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    width: 370px;
    min-height: 60vh;
    border-radius: 5px;
    padding: 1rem;
    margin-right: 8rem;
    /* background-color: rgba(255, 255, 255, 0.13); */
    box-shadow: 0 0 40px rgb(8 7 16 / 60%);
    background-color: rgba(110, 109, 109, 0.13);

}


.form-container2 {
    color: #fff;
}

.form-container2 input[type="email"] {
    background: rgba(255, 255, 255, 0.1);
    border: none;
    color: white;
    box-shadow: none;
}

#welcome {
    color: rgb(174, 174, 174);
}

#register {
    color: white;
}

.form-container2 input[type="email"] {
    box-shadow: none;
    font-size: 12px;
    font-weight: 500;
    height: 38px;
}

.email-input2 input[type="email"]:focus,
input[type="email"]:active {
    border: none;
    outline: none;

}

.form-container2 input::placeholder {
    font-size: 13px;
    font-weight: 400;

}

.custom-btn2 {
    border-radius: 30px;
    padding: 5px;
    border: none;
    width: 100%;
}

#google2 {
    height: 25px;
    width: 25px;
    margin-right: 1rem;
}

.brown-btn2 {
    background-color: #212123;
    border-radius: 8px;
    border: none;
    height: 45px;
    color: white;
    width: 100%;
}

.brown-btn2:hover {
    background-color: #966837;
}

.footer-links2 {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    /* min-width: 30%; */
    margin-top: 1rem;
    margin-left: 3rem;
    /* background-color: #920b82; */

}






.footer-links2 ul {
    display: flex;
    align-self: flex-start;
    justify-content: space-around;
    list-style: none;
    /* background-color: blue; */
    padding-left: 0;


}

.footer-links2 a {
    display: flex;
    text-decoration: none;
    color: #fff;
    margin-right: 3rem;
    font-weight: 400;
    font-size: 16px;
}

.footer-links2 a:hover {
    color: #966837;
}

.copy-right2 p {
    color: white;
    font-size: 13px;
    font-weight: 200;

    /* background-color: #0B9251; */
}

/* New login page ends here */



/* New Login page starts here */
.login-container {
    position: relative;
    background-image: url('../img/bg.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    z-index: 1;

    /* background-color: rgba(0, 0, 0, .4); */
}

.login-container::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
    z-index: -1;
}


/* New Login page ends here */



/*! Registrartion Form  starts here */


#reg-welcome {
    color: rgb(174, 174, 174);
    font-size: 13px;
}

.register-container2 {
    position: relative;
    background-image: url('../img/bg.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.register-container2::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
    z-index: -1;
}

.main-row {
    color: #fff;
    width: 90vw;
    /* padding: 1rem;  */
}



.login__logo3 {
    width: 95%;
    display: flex;
    margin-top: 1rem;
}


.login__logo3 img {
    left: 0;
    height: 5rem;


}

.main-row .signup-btn {
    background-color: #212123;
    border-radius: 8px;
    border: none;
    color: white;
    width: 100%;
}

.main-row .signup-btn:hover {
    background-color: #966837;
}

.register-container2 .logo_footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100vw;
    /* background-color: rgb(255, 0, 0); */
}

.register-container2 .form-holder {
    /* background-color: #3D64CC; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    width: 37vw;
    border-radius: 5px;
    margin-right: 1rem;
    align-self: flex-end;
    /* background-color: rgba(255, 255, 255, 0.13); */
    box-shadow: 0 0 40px rgb(8 7 16 / 60%);
    /* background-color: rgba(110, 109, 109, 0.13); */
}


.register-container2 .form-container2 {
    font: 100;
    font-size: 13px;
}

.register-container2 .footer-links2 {
    align-self: flex-start;
    /* background-color: #3D64CC; */
}

/* .reg-form-container {
    height: inherit; 
     background-color: #0B9251;

} */

.reg-form-container form {
    /* background-color: #0B9251; */
    /* background-color: #009edd; */
    margin-bottom: -1rem;
}

.reg-form-container input {
    border: none;
    outline: none;
    background-color: transparent;
    color: #fff;

}

.reg-form-container input::placeholder {
    font-size: 13px;
    font-weight: 400;
    color: #979797;

}

.reg-form-container input[type="email"],
.reg-form-container input[type="text"] {
    background: rgba(255, 255, 255, 0.1);
    border: none;
    color: white;
    box-shadow: none;
    font-size: 12px;
    font-weight: 500;
    height: 38px;
}

input[type="text"] {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: none;
    height: 38px;
    color: #777777;
}


.reg-control {
    /* background-color: #0B9251; */
    background: rgba(255, 255, 255, 0.1);
    border: none;
    color: white;
    box-shadow: none;
    font-size: 12px;
    font-weight: 500;
    height: 38px;
    padding: 0 10px;
}


/* .reg-form-container input[type="email"]:focus {
    background-color: #0B9251;
} */

.reg-form-container input[type="email"]:focus,
.reg-form-container input[type="text"]:focus {
    /* border: none; */
    outline: none;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    box-shadow: none;
}


input[type="text"]:focus,
input[type="text"]:active {
    /* border: none; */
    outline: none;
    background: rgba(255, 255, 255, 0.1);
    color: #777777;
    box-shadow: none;
}


.error {
    color: red;
    z-index: -1;
    position: relative;
    font-size: 12px;
    margin-bottom: -1rem;
    margin-left: 5px;
}

.account {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

}

.account p {
    color: #fff;
}

/*! Registration Form ends here */


/* Navbar  starts here */

.nav-container {
    min-width: 100vw;
}

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
}

.logo img {
    margin-left: 1rem;
    max-width: 240px;
}


.link-container {
    color: white;
    display: flex;
    margin-right: 1rem;

}

.link-container a {
    color: white;
}

.link-container a:hover {
    color: #966837;
}

/* Navbar ends here */


/* Form  starts here */


.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 80vh;
    min-height: 100%;
    min-width: 100vw;
    display: flex;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    color: white;
}

.form-container input[type="email"] {
    background: rgba(255, 255, 255, 0.1);
    border: none;
    color: white;
    min-width: 350px;
    box-shadow: none;
}

.email-input input[type="email"]:focus,
input[type="email"]:active {
    border: none;
    outline: none;
}

.form-container input::placeholder {
    font-size: 12px;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.5);

}

.form_btn {
    margin-top: 1.5rem;
}

.form_btn:hover {
    background-color: #966837;
}

.form-container #google {
    height: 25px;
    width: 25px;
    margin-right: 1rem;
}




/*!-----------> NOT REFLECTING */

/* .form-container #welcome {
    font-size: 13px;
    font-weight: 400;
} */

.form-container #name {
    color: #966837;

}

.remember-me {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
}

.remember-me a {
    color: white;
    text-decoration: none;
}

.remember-me a:hover {
    color: #966837;
    text-decoration: none;
}


.need-help a {
    font-size: 13px;
    font-weight: 400;
}


/* .form-container .btn {
    background-color: #966837;
    border-radius: 30px;
    border: none;
    width: 100%;
} */

.form-container .btn:hover {
    background-color: #aa7d4c;
}

.or {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 1px;
    margin-top: 22px;
    text-align: center;
    color: #777777;
    margin-bottom: 1rem;
    /* background-color: aqua; */

}

.or-left {
    background-color: #777777;
    border: 0px solid #777777;
    width: 50%;
    position: relative;
    top: 10px;
    margin-right: 5px;
}

.or-right {
    background-color: #777777;
    border: 0px solid #777777;
    width: 50%;
    position: relative;
    top: 10px;
    margin-left: 5px;
}

#register {
    margin-top: 30px;
    text-align: center;
    /* background-color: aqua; */
}


#reg a {
    margin-left: 5px;
    text-decoration: none;
    color: #966837;
}

#reg a:hover {
    color: #e0ac75;
}

/* Form ends here */


/* Footer section starts here */

.footer-container {
    display: flex;
    flex-wrap: wrap;
    font-family: 'Poppins', sans-serif;
    min-width: 100%;
}


.copy-right {
    margin-left: 1rem;
}

.copy-right p {
    color: white;
}

.footer-links {
    display: flex;
    justify-self: space-between;
    width: 55%;
    /* background-color: aqua; */
}

.footer-links ul {
    display: flex;
    justify-content: space-around;
    margin-left: 10rem;
    list-style: none;
    text-decoration: none;
    width: 100%;
    /* background-color: blue; */
}

.logo_dark {
    max-width: 240px;
}

.footer-links a {
    margin-right: 20px;
    text-decoration: none;
    color: white;

}

.footer-links a:hover {
    color: #966837;
}

/* Footer section ends here */


/* main header starts here  */

.header.navbar {
    width: 100%;
    padding: 2px 20px;
    justify-content: space-between !important;
}

.header .navbar-collapse {
    flex-grow: 0;
}

.header.navbar a.nav-link {
    color: #fff;
    font-family: 'Poppins', sans-serif;
}

.header.navbar a.nav-link.active {
    color: #966837;
}

.headerIcon {
    max-height: 22px;
    width: auto;
}

.userIcon {
    max-width: 20px;
    border-radius: 50px;
    margin-left: 15px;
}

.logoutDropdown .dropdown-menu {
    min-width: 100px;
}

.logoutDropdown .dropdown-toggle {
    padding-left: 0 !important;
}

/* product detail page css starts here */

/* breadcrumb css */

.innerDetails {
    /* background: url(../img/bg.webp);
    background-size: 100%;
    background-position: top center;
    background-repeat: no-repeat; */
    width: 100%;
    position: relative;
}

.productBanner::before,
.bannerSec::before {
    content: "";
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.productBanner {
    position: relative;
}

.productBanner .breadCrumb {
    position: absolute;
    bottom: 80px;
    width: auto;
}

.banImg {
    width: 100%;
}

.breadCrumb {
    width: 100%;
    padding: 20px 0;
}

.breadCrumb ul {
    margin: 0;
    padding: 0;
}

.breadCrumb ul li {
    display: inline-block;
    color: #fff;
    font-size: 18px;
}

.breadCrumb ul li img {
    max-height: 10px;
    margin: 0 10px;
}

/* filters css */

.filtersSec {
    width: 100%;
    display: inline-block;
}

.filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 50px;
}

.filters label {
    color: #fff;
    margin-bottom: 5px;
}

.filters .formGroup {
    width: 25%;
}

.filters select.form-select {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.filters .btn img {
    max-height: 20px;
}


.filters .btn {
    width: 25%;
    margin-top: 30px;
    margin-bottom: 0;
    line-height: 1;
}

/* search input css */

.search {
    width: 100%;
    margin-bottom: 50px;
}

.search .input-group-text {
    background: #fff;
    border: 1px solid #ddd;
}

.search span img {
    max-height: 25px;
}

.search input {
    border: 0;
    padding: 15px 10px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.search input.form-control:focus {
    box-shadow: none;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.search .searchInputBtn {
    background: #966837;
    width: 150px;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
}

.sorting {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 50px;
}

.sorting .form-select {
    width: auto;
    font-size: 16px;
    cursor: pointer;
    padding-top: 10px;
    padding-bottom: 10px;
}

.w-100px {
    width: 100px;
}

.btn.whiteBtn img {
    cursor: pointer;
}

/* product card css starts */

.productSliderRow {
    margin-top: 50px;
}

.productSliderRow .swiper {
    padding: 0 40px !important;
}

.productCard {
    width: 100%;
    margin-bottom: 20px;
    background: #fff;
    box-shadow: 0 0 8px #eee;
    border: 1px solid #eee;
    min-height: 455px;
    /* min-height: 300px; */
    /* background-color: #02aff1; */
}

.productCard a img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.details {
    padding: 10px;
}

.contnt p {
    margin-bottom: 5px;
    /* display: -webkit-box;
    -webkit-line-clamp: 6; 
    -webkit-box-orient: vertical;
    overflow: hidden; */
    font-size: 15px;
}

.custom_9{
    height: 160px;
    overflow: hidden;
}

.hoverContent .dot{
    position: absolute;
}



.contnt p span {
    margin-right: 0 !important;
    font-size: 15px !important;
}

.detailsHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 56px;
}

.detailsHeading a {
    text-decoration: none;
    color: #000;
}

.detailsHeading a:hover {

    color: #966837;
}

.availablity {
    /* background-color:#0EC970; */
    background-color: rgba(14, 201, 112, 0.1);
    color: #0B9251;
    border-radius: 50px;
    padding: 3px 10px;
    margin-bottom: .5rem;
    width: 85px;
    text-align: center;
}

.details p span {
    color: #000;
    margin-right: 5px;
}

.btn.borderBtn {
    background: transparent;
    color: #000;
    border: 1px solid #ddd;
}

.btn.borderBtn:hover {
    background: #eee !important;
    border: 1px solid #eee !important;
    color: #000 !important;
}



.details .btn {
    display: block;
}

.rating {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 15px;
}

.rating img {
    max-width: 20px;
}

.description {
    display: none;
}

.jsListView .col-lg-3 {
    width: 100%;
}

.jsListView .col-lg-3 .productCard {
    display: flex;
    flex-direction: row;
}

/* .jsListView .col-lg-3 .productCard img {
    width: 100%;
} */

.jsListView .col-lg-3 .details {
    width: 80%;
    padding: 15px;
}

.jsListView .col-lg-3 .contnt {
    width: 80%;
}

.jsListView .col-lg-3 .contentDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.jsListView .col-lg-3 .description {
    display: block;
}

/* .productDetailCards {
    position: relative;
    padding-bottom: 60px;
    margin-top: 2rem;
}

.productDetailCards .brownBtn {
    position: absolute;
    bottom: 10px;
    left: 10px;
} */

.swiper .productCard {
    min-height: 465px;
}

/* footer section css */

.footerSec {
    background: #fff;
    width: 100%;
    padding-bottom: 50px;
}

.footerSec .container {
    border-top: 1px solid #ddd;
    padding-top: 50px;
}

.socialIcons img {
    max-width: 25px;
    margin-right: 5px;
}

.socialIcons a {
    color: inherit;
    text-decoration: none;
}

.footerDetails h6 {
    margin-bottom: 15px;
    font-size: 1.1rem;
}

.footerDetails p {
    margin-bottom: 5px;
}

.footerDetails p a {
    color: #333333;
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
}


.footerDetails p a:hover {
    color: #966837;
}

.text-brown {
    color: #966837;
}

.copyright {
    margin-top: 50px;
    width: 100%;
}


/* single prduct view page */

.bannerSec {
    position: relative;
    width: 100%;
}

.bannerImg {
    width: 100%;
    height: 500px;
    object-fit: cover;
}

.bannerContent {
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    width: 100%;
}

.dflxCstmCntr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.bannerHeading {
    align-items: center;
    display: flex;
}

.bannerHeading h1 {
    margin-right: 10px;
}

.greenBg {
    background: #0EC970;
}

.shareDiv label {
    color: #fff;
    margin-right: 15px;
}

.shareDiv label img {
    max-height: 20px;
}

.AboutSec {
    width: 100%;
    margin: 70px 0;
}

.AboutSec .nav.nav-tabs {
    border-bottom: 1px solid #d2d2d2;
    display: inline-flex;
    width: 100%;
    justify-content: flex-start;
}

.AboutSec .nav-link {
    color: #d2d2d2;
}

.AboutSec .nav-link:hover,
.AboutSec .nav-link:focus {
    color: #d2d2d2;
    border: 0;
}

.AboutSec .nav-link.active {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #000 !important;
    color: #000 !important;
}

.relatedProduct .swiper {
    padding: 0 40px !important;
}

.relatedProduct .swiper-button-prev {
    left: 0px;
    top: 46%;
}

.relatedProduct .swiper-button-next {
    right: 0px;
    top: 46%;

}

.relatedProduct .swiper-button-next:after,
.relatedProduct .swiper-button-prev:after {
    background: #966837;
    color: #fff !important;
    padding: 10px;
    font-size: 25px !important;
}

#extraSpace {
    height: 800px;
}

/* video sec starts here */

.videoSec {
    width: 100%;
    margin: 100px 0;
    position: relative;
}

.videoSec .btnOuter {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;
    width: 100%;
}

.btn-play {
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    outline: none;
    cursor: pointer;
    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all 0.25s ease-out;
    position: relative;
    display: inline-block;
    font-size: 52px;
    width: 92px;
    height: 92px;
    vertical-align: middle;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
}


.btn-play:before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    background-color: #fff;
    -webkit-transition: all .8s ease-out;
    -moz-transition: all .8s ease-out;
    -o-transition: all .8s ease-out;
    transition: all 0.8s ease-out;
    -webkit-box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.1);
    content: ' ';
}

.btn-play:after {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    -moz-transform: translate3d(-50%, -50%, 0);
    -ms-transform: translate3d(-50%, -50%, 0);
    -o-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    -webkit-filter: blur(0);
    -moz-filter: blur(0);
    filter: blur(0);
    border-style: solid;
    border-width: 8px 0 8px 14px;
    border-color: transparent transparent transparent #343840;
    content: ' ';
    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all 0.25s ease-out;
}

.btn-play:hover,
.btn-play:focus,
.btn-play:active,
.btn-play:focus:active {
    outline: none;
}

.btn-play:hover:before,
.btn-play:focus:before,
.btn-play:active:before,
.btn-play:focus:active:before {
    -webkit-transform: scale(1.3, 1.3);
    -moz-transform: scale(1.3, 1.3);
    -ms-transform: scale(1.3, 1.3);
    -o-transform: scale(1.3, 1.3);
    transform: scale(1.3, 1.3);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    visibility: visible;
}

.btn-play:hover:after,
.btn-play:focus:after,
.btn-play:active:after,
.btn-play:focus:active:after {
    border-color: transparent transparent transparent #3D64CC;
}


.videoModal .btn {
    position: absolute;
    top: -25px;
    right: -25px;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


/* feature sec starts here */


.featuresSec {
    width: 100%;
    margin: 60px 0;
}

.feature {
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    margin-bottom: 30px;
    flex-direction: column;
}

.feature img {
    margin-bottom: 10px;
    max-width: 60px;
}

.feature p {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
}

.tobarSearch {
    position: relative;
}

.tobarSearch input {
    border: 0;
    outline: 0;
    position: relative;
    width: 20px;
    background: url(../img/search.png);
    background-size: 28px;
    background-repeat: no-repeat;
    background-position: top right;
    transition: all 0.5s ease-in-out;
}

.tobarSearch .headerIcon {
    position: absolute;
    top: 0;
}

.tobarSearch input:focus {
    width: 150px;
    box-shadow: none;
    padding-right: 30px;
    background-position: right;
}


/*========== homepage css starts here======= */

/* slider sec starts here */
.header.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2;
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.7);
}

.header .dropdown-menu {
    min-width: 7rem;
}

.header .dropdown-item:active {
    background-color: #966837;
}

.header .btn {
    padding: 10px 15px !important;
}

.sliderContent {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    z-index: 1;
    left: 6%;
}

.sliderContent h2 {
    color: #966837;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2.8rem;
}

.sliderContent p {
    color: #fff;
    font-size: 21px;
}

.sliderContent .btn {
    margin-top: 20px;
}

.sliderContent h1 {
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 2.6rem;
}

.slideOuter::before {
    content: "";
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0%;
    right: 0;
    top: 0;
    bottom: 0;
}

.slideOuterNew::before {
    content: "";
    background: rgba(0, 0, 0, 0.73);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0%;
    right: 0;
    top: 0;
    bottom: 0;
}

.counter {
    margin-bottom: 30px;
}

.counter ul {
    margin: 0;
    padding: 0;
}

.counter ul li {
    display: inline-block;
    list-style: none;
    /* color: #fff; */
    color: #212123;
    position: relative;
    margin-right: 20px;
    padding-right: 20px;
}

.counter ul li::after {
    content: "";
    height: 70%;
    width: 1px;
    background: #fff;
    position: absolute;
    right: 0;
    top: 0;
    color: #212123;

}

.counter ul li:last-child::after {
    content: none;
}

.counter p {
    margin: 0;
    font-size: 19px;
    text-align: center;
    font-weight: 500;
}

.sliderContent .counter p {
    text-align: left;
}

.counter ul li h2 span {
    font-weight: 500;
    color: #966837;
    position: relative;
    left: -8px;
    font-size: 35px;
}

.sliderSec .swiper-button-next:after,
.sliderSec .swiper-button-prev:after {
    color: #fff;
    font-size: 20px;
}

.sliderSec .swiper-pagination-bullet-active {
    background: #fff;
}

.sliderSec .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
}

/* about us section */

section {
    padding: 80px 0;
}

.sectionTitle h6 {
    color: #966837;
    text-transform: uppercase;
    font-size: 1.1rem;
}

.sectionTitle h1 {
    color: #000;
    font-weight: 600;
    margin-bottom: 10px;
}

.sectionTitle p {
    font-weight: 500;
    font-size: 17px;
}

.aboutSec p {
    line-height: 2;
    font-size: 18px;
}

/* product section */

.prodctSec {
    /* background: url(../img/bg_product.webp);
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat; */
    width: 100%;
    position: relative;
    padding-top: 120px;
}

.prodctSec .counter {
    margin: 20px 0 70px;
}

.prodctSec .counter h2 {
    font-size: 3.5rem;
}

.prodctSec .counter h2 span {
    font-size: 3rem;
    left: -10px;
}

.linkBtn {
    text-decoration: none;
    color: #966837;
    font-size: 18px;
}

.linkBtn:hover {
    color: #835c32;
}

/* application area css */

.bg_light_grey {
    background: #f2f2f2;
}

.applicationRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    margin-top: 70px;
}

.applicationCard {
    background: #F6F6F6;
    width: 32.3%;
    text-align: center;
}

.applicationCard img {
    width: 100%;
    margin-bottom: 10px;
}

.applicationCard h5 {
    color: #966837;
    padding: 20px 0;
}


/* certificates css */

.certificates {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}

.certificates img {
    max-width: 150px;
    height: auto;
    object-fit: contain;
    filter: opacity(0.6);
}

/* features sec starts  */

.customerFeature {
    margin-bottom: 70px;
}

.featureRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.featureDiv {
    max-width: 260px;
    background: rgba(217, 217, 217, 0.1);
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.featureDiv img {
    max-width: 50px;
    margin-bottom: 10px;
}

.featureDiv p {
    margin: 0;
    font-weight: 500;
}

/* clients sec */


.clientsSec .nav.nav-tabs {
    display: inline-flex;
    border: 0 !important;
    margin-top: 40px;
}

.clientsSec .nav.nav-tabs .nav-link {
    color: #000;
    border: 0 !important;
    font-size: 16px;
    padding-left: 0;
    margin-right: 8px;
    font-weight: 600;
    padding-right: 8px;
}

.clientsSec .nav.nav-tabs li.nav-item:last-child .nav-link {
    margin-right: 0;
    padding-right: 0;
}

.clientsSec .nav.nav-tabs .nav-link.active {
    color: #966837;
    background: transparent;
}


.clientsSec .counter h2 {
    font-size: 5.5rem;
    color: #000;
}

.clientsSec .counter ul li::after {
    background: #eee;
}

.clientsSec .counter ul li {
    margin-right: 50px;
    padding-right: 50px;
}

.clients {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    margin: 30px 0 20px;
}

.clients img {
    object-fit: contain;
    max-width: 200px;
    /* filter: grayscale(100%); */
    /* box-shadow: 0 0 9px #f5f5f5; */
    margin: 10px;
    padding: 10px;
    /* border: 1px solid #f5f5f5; */
}

.clients img:hover {
    /* filter: grayscale(0); */
    cursor: pointer;
}

.clientsSec .swiper-button-prev {
    left: -5px;
}

.clientsSec .swiper-button-next {
    right: -5px;
}

/* team section css */

.teamCard {
    text-align: center;
    width: 100%;
}

.teamCard img {
    width: 100%;
    max-width: 250px;
    border-radius: 50px;
    margin-bottom: 15px;
}

.teamCard h5 {
    color: #966837;
    margin-bottom: 2px;
}

.teamCard p {
    font-weight: 600;
    font-size: 20px;
}

.teamRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 40px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    color: #966837;
    font-size: 35px !important;
}

/* contact us section */

/* .contactDetails {
    padding-left: 30px;
    position: relative;
    padding-top: 60px;
} */

.contact {
    margin: 20px 0;
}

.contact h6 {
    /* color: #777777; */
    font-size: 16px;
    margin-bottom: 5px;
}


.contact p {
    margin-bottom: 5px;
    color: #212123;
    font-weight: 500;
    font-size: 17px;
}

.contact p a {
    text-decoration: none;
    color: inherit;
    font-size: 17px;
}

.contact p img {
    max-height: 18px;
    width: auto;
    position: absolute;
    left: 0;
}

.contactForm .form-label {
    font-weight: 500;
    color: #333;
}

.contactForm .form-control {
    border: 1px solid #eee;
    border-radius: 3px;
    font-family: 'Poppins', sans-serif !important;
    color: #777777;
    background: #fff;
    height: 40px;
}

.contactForm .btn {
    padding: 10px;
    border-radius: 0px;
}

.PhoneInput input,
input:focus {
    border: 0;
    box-shadow: none;
    outline: 0;
}

.Toastify div {
    font-family: 'Poppins', sans-serif;
}


/* terms and conditon page */

.TermsPage .header {
    position: relative;
    background: rgba(0, 0, 0, 0.7)
}


/* customer toaster css */

.customNotifyToast {
    width: 420px;
    right: 95px;
    transition: all 0.4s ease-in-out;
}

/* .customNotifyToast .Toastify__toast-body {
    padding-left: 50px;
} */

/* .Toastify__toast-container::before {
    content: "";
    background: rgba(0, 0, 0, 0.1);
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
} */

.customNotifyToast .notifyToast {
    padding-left: 50px;
}

.customNotifyToast div {
    transition: all 0.4s ease-in-out;
}

.customNotifyToast .Toastify__toast-icon svg {
    display: none;
}

.customNotifyToast .Toastify__close-button {
    opacity: 1;
}

.customNotifyToast .Toastify__close-button>svg {
    color: red;
    opacity: 1;
}

.bell {
    max-width: 100px;
    position: absolute;
    left: -5px;
    z-index: -1;
}

.email {
    max-width: 150px;
}

.divsecond {
    display: none;
}

.expandToast .divfirst {
    display: none;
}

.expandToast .divsecond {
    display: block;
}

.customNotifyToast .notifyToast.expandToast {
    padding-left: 0px;
    text-align: center;
}


.Toasttxt h4 {
    color: #727cf5;
    margin-bottom: 5px;
}

.Toasttxt p {
    color: #777;
    font-size: 15px;
}

.customNotifyToast .solidBtn {
    background-color: #727cf5;
    border-color: #727cf5;
    color: #fff;
}

.customNotifyToast .solidBtn:hover {
    background-color: #6169d0;
    border-color: #6169d0;
    color: #fff;
}

.customNotifyToast .borderWhiteBtn {
    background-color: transparent;
    border-color: #ddd;
    color: #727cf5;
}

.customNotifyToast .borderWhiteBtn:hover {
    background-color: #727cf5;
    border-color: #727cf5;
    color: #fff;
}

.customNotifyToast .Toastify__progress-bar--success {
    /* display: none;
     */
    opacity: 0 !important;
}



/* Table CSS starts here */

.table-container {
    margin-top: 2rem;
}

.dataCustomTable td,
.dataCustomTable th {
    text-align: center;
}

.dataCustomTable a {
    text-decoration: none;
    color: #000;
}

.bg_light_blue {
    background: #b3c6e7;
}

.bg_sky_blue {
    background: #02aff1;
}

.bg_dark_yellow {
    background: #ffc001 !important;
}

.bg_light_yellow {
    background: #fed966 !important;
}

.bg_green {
    background: #568235;
}

.vendor-login-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #ddd;
    padding-bottom: 50px;
}

/* Table css ends here */

.errrTxt {
    color: red;
    margin: 0;
}



/*===================================responsive start here================================================*/

/* For 4K Displays (Ultra HD) */
@media screen and (min-width: 3840px) {}

/* For Projectors or Higher Resolution Screens (Full HD) */
@media screen and (min-width: 1920px) {}



@media only screen and (min-width: 768px) and (max-width: 1024px) {

    .sliderContent h2 {
        font-size: 2rem;
    }

    .sliderContent h1 {
        font-size: 1.5rem;
    }

    .applicationCard {
        width: 31.3%;
    }

    .teamCard img {
        max-width: 150px;
    }

    .clients img {
        max-width: 150px;
    }

    .footerDetails p a {
        word-break: break-all;
    }

    .login-container2 {
        justify-content: flex-start;
    }


}


/*============================= Tablet landscape size to standard 960  =============================*/
@media only screen and (min-width: 959px) and (max-width: 1024px) {

    .clientsSec .counter h2 {
        font-size: 3.5rem;
    }


    .clientsSec .counter h2 {
        font-size: 3.5rem;
    }


}

/* ============================= global tablet and mobile =============================*/
@media only screen and (max-width: 959px) {

    .header .logo img {
        margin: 0;
        max-width: 200px;
    }

    .header .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }

    .header .navbar-toggler {
        padding: 0;
        border: 0;
        box-shadow: none;
    }

    .footerDetails .text-end {
        text-align: left !important;
    }


    .form-holder {
        width: 60%;
        margin: 25px auto;
        min-height: inherit;
        height: auto;
    }



    .footer-links2 {
        align-self: center;
        margin-top: 0;
        margin-left: 0;
    }

    .footer-links2 a {
        margin-right: 15px;
    }

    .copy-right2 p {
        text-align: center;
    }

    .main-container {
        height: auto;
    }

    .register-container2 .footer-links2 {
        align-self: center;
    }

    .header .btn {
        margin: 0px 0 10px !important;
        width: 100px;
    }




}


/* ================Tablet Portrait size to standard 960 (devices and browsers) ================*/
@media only screen and (min-width: 768px) and (max-width: 959px) {

    .filters {
        margin-bottom: 20px;
    }

    .register-container2 .form-holder {
        /* background-color: #0B9251; */
        align-self: flex-start;
        margin-top: 1.5rem;
        width: 100%;
    }

}

/* ==================All Mobile Sizes (devices and browser) ==================*/

@media only screen and (max-width: 767px) {

    section {
        padding: 40px 0;
    }

    .bannerContentRow {
        flex-direction: column;
    }

    .bannerImg {
        height: 300px;
    }

    .bannerHeading {
        width: 100%;
        justify-content: space-between;
    }

    .shareDiv label {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .productAction,
    .productDetails {
        width: 100%;
    }

    .aboutProdct .w-25.mb_w_100 {
        width: 100% !important;
    }

    .AboutSec .nav-link {
        padding: 0 15px 0 0;
    }

    .videoSec {
        margin: 50px 0;
    }

    .videoSec img {
        height: 300px;
        object-fit: cover;
    }

    .featureImg img {
        width: 100%;
    }

    .videoModal .btn {
        top: -35px;
        right: -7px;
    }

    .footerSec {
        padding: 50px 0 20px;
    }

    .copyright p {
        text-align: center;
    }

    .filters {
        flex-direction: column;
        margin: 15px 0;
    }

    .filters .formGroup {
        width: 100%;
    }

    .filters label {
        color: #000;
    }

    .filters .btn {
        width: 100%;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .search .input-group-text {
        border: 1px solid #966837;
    }

    .search input {
        border-top: 1px solid #966837;
        border-bottom: 1px solid #966837;
    }

    .sectionTitle h1 {
        font-size: 1.3rem;
    }


    .sliderContent {
        left: 7%;
    }

    .sliderContent h1 {
        font-size: 1.6rem;
    }

    .sliderContent h2 {
        font-size: 1.3rem;
    }

    .swiper-button-next::after,
    .swiper-button-prev::after {
        color: #966837;
        font-size: 25px !important;
    }

    .sliderContent .btn {
        margin-top: 0;
        padding: 6px 8px;
    }

    .slideOuter video,
    .slideOuter video source {
        height: 350px;
        object-fit: cover;
    }

    .sliderContent .counter ul li {
        margin-bottom: 5px;
        padding: 0;
        width: 49%;
        margin-right: 0;
    }

    .sliderContent .counter ul li::after {
        content: none;
    }

    .sliderContent .swiper-button-prev,
    .sliderContent .swiper-rtl .swiper-button-next {
        left: 0;
    }

    .sliderContent .swiper-button-next,
    .sliderContent .swiper-rtl .swiper-button-prev {
        right: 0;
    }

    .header.navbar {
        position: relative;
        background: #000;
    }

    .counter ul li h2 span {
        font-size: 28px;
    }

    .sliderContent .counter ul li h2 {
        margin-bottom: 0;
    }

    .applicationCard {
        width: 47%;
    }

    .applicationCard h5 {
        padding: 5px;
        font-size: 1rem;
    }

    .featureRow {
        flex-wrap: wrap;
    }

    .featureDiv {
        width: 100%;
        max-width: 100%;
    }

    .clientsSec .counter h2 {
        font-size: 2.5rem;
        color: #000;
        margin-bottom: 0;
    }

    .clientsSec .counter ul li {
        margin-right: 0;
        margin-bottom: 10px;
        padding-right: 0;
        width: 100%;
        text-align: left;
    }

    .clientsSec .counter p {
        text-align: left;
        font-size: 18px;
    }

    .teamRow {
        flex-wrap: wrap;
    }

    .contactDetails {
        padding-top: 0;
    }

    .contactForm {
        margin-top: 20px;
    }

    .productCard {
        margin-bottom: 5px;
    }

    .aboutSec p {
        line-height: 1.5;
        font-size: 16px;
    }

    .applicationRow {
        margin-top: 10px;
    }

    .form-holder {
        width: 96%;
        margin: 0 auto;
        min-height: inherit;
        height: auto;
        padding: 10px;
    }

    .register-container2 .footer-links2 {
        align-self: center;
    }

    .reg-form-container,
    .register-container2 .logo_footer {
        padding: 0 !important;
    }

    .productBanner .breadCrumb {
        bottom: 0;
    }

    .userIcon {
        margin-left: 0px;
    }

    .footerDetails {
        text-align: center;
    }

  







}



/* ===================Mobile Landscape Size to Tablet Portrait (devices and browsers)=================== */
@media only screen and (min-width: 480px) and (max-width: 767px) {




    .register-container2 .form-holder {
        /* background-color: #0B9251; */
        align-self: flex-start;
        margin-top: 1.5rem;
        width: 100%;
    }



}

/* =====================Mobile Portrait Size to Mobile Landscape Size (devices and browsers)===================== */

@media screen and (max-width: 479px) {

    .productCard {
        margin-bottom: 15px;
        min-height: inherit;
    }

    .register-container2 .form-holder {
        /* background-color: #0B9251; */
        align-self: flex-start;
        margin-top: 1.5rem;
        width: 100%;
    }

    .sorting .btn {
        display: none;
    }

    .main-container {
        height: 100vh;
    }

    .reg-form-container .form-label {
        font-size: 14px;
    }

}


/*! Changes required may need to be deleted later */
.active-ptab {
    background-color: #d0cfcf !important;

}


.not-active-ptab {
    background-color: white;
}